import { ModuleContentResolver } from '../../contexts'

import { abstractModuleContentResolver } from '../abstract/abstract-resolver'
import {
  ProductApplicationSliderModuleContent,
  ProductApplicationSliderModuleContentInput
} from './ProductApplicationSlider.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const resolveProductApplicationSliderModuleContent: ModuleContentResolver<ProductApplicationSliderModuleContentInput, ProductApplicationSliderModuleContent> =
  abstractModuleContentResolver
