
import { AvailableModuleDriver } from '../../modules/allowed'

// Modules content resolvers
import { resolveCTAModuleContent } from '../../modules/CTABox/CTABox.helpers'
import { resolveImageModuleContent } from '../../modules/Image/Image.helpers'
import { resolveNavigationCTABoxModuleContent } from '../../modules/NavigationCTABox/NavigationCTABox.helpers'
import { resolveCustomerSupportModuleContent } from '../../modules/CustomerSuport/CustomerSupport.helpers'
import { resolveTextModuleContent } from '../../modules/Text/Text.helpers'
import { resolveProductApplicationSliderModuleContent } from '../../modules/ProductApplicationSlider/ProductApplicationSlider.helper'
// Modules content resolvers END

import { PossibleSnippet } from './contracts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const PossibleSnippets: Record<PossibleSnippet, string> = {
  CTABox: AvailableModuleDriver.CTABox,
  CustomerSupport: AvailableModuleDriver.CustomerSupport,
  Image: AvailableModuleDriver.Image,
  NavigationCTABox: AvailableModuleDriver.NavigationCTABox,
  Text: AvailableModuleDriver.Text,
  ProductApplicationSlider: AvailableModuleDriver.ProductApplicationSlider,
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const snippetResolversMap = {
  [PossibleSnippets.CTABox]: resolveCTAModuleContent,
  [PossibleSnippets.CustomerSupport]: resolveCustomerSupportModuleContent,
  [PossibleSnippets.Image]: resolveImageModuleContent,
  [PossibleSnippets.NavigationCTABox]: resolveNavigationCTABoxModuleContent,
  [PossibleSnippets.Text]: resolveTextModuleContent,
  [PossibleSnippets.ProductApplicationSlider]: resolveProductApplicationSliderModuleContent,
}
