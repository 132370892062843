var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Navbar__mobile",class:{'Navbar__mobile--profile': _vm.profileHelperMenuOpen}},[(!_vm.shouldHideMobileNav || (_vm.shouldHideMobileNav && !_vm.isPageWithoutMobileNav))?_c('div',{ref:"navigation",staticClass:"brand-wrapper",class:{'--withTopbar': _vm.hasTopBar }},[(_vm.logo)?_c('Logo',{attrs:{"logo":_vm.logo}}):_vm._e(),(_vm.hasLanguages && _vm.currentLocale)?_c('div',{staticClass:"languages-nav"},[_c('LanguagesDropdown',_vm._b({},'LanguagesDropdown',{ languagesLinks: _vm.languagesLinks, currentLocale: _vm.currentLocale },false))],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"sidebar-menu",class:{ '--open': _vm.isMenuOpen || _vm.profileHelperMenuOpen}},[_c('div',{staticClass:"brand-wrapper"},[(_vm.logo)?_c('Logo',{attrs:{"logo":_vm.logo}}):_vm._e(),(_vm.hasLanguages && _vm.currentLocale)?_c('div',{staticClass:"languages-nav"},[_c('LanguagesDropdown',_vm._b({},'LanguagesDropdown',{ languagesLinks: _vm.languagesLinks, currentLocale: _vm.currentLocale },false))],1):_vm._e()],1),_c('div',{staticClass:"sidebar-content-wrapper"},[(_vm.profileHelperMenuOpen)?[(_vm.profileTitle)?_c('UiParagraph',{staticClass:"site-meta-title"},[_vm._v(_vm._s(_vm.profileTitle))]):_vm._e(),_vm._l((_vm.profileSnippetModules),function(module){return _c(_vm.getModule(module.driver),{key:module.id,tag:"component",attrs:{"data":module}})})]:_c('div',{staticClass:"search-wrapper"},[_c('SearchResults',_vm._b({},'SearchResults',{
          showDynamicResults: !_vm.simpleSearch,
          isOpen: !_vm.simpleSearch,
          inputPlaceholder: _vm.$t('front.shared.navbar.NavbarMobileSimple.searchInputPlaceholder').toString()
        },false))],1),_c('div',{staticClass:"navigation-wrapper"},[(_vm.profileHelperMenuOpen)?[_c('UiNav',_vm._b({staticClass:"Navbar__profile-helper-links",scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var element = ref.element;
return [_c('UiLink',_vm._b({staticClass:"navbar-link"},'UiLink',{ target: element.target, label: element.label, tabTarget: element.tabTarget },false))]}}],null,false,851317365)},'UiNav',{ links: _vm.profileMenu },false))]:[_c('UiNav',_vm._b({staticClass:"Navbar__main-links",scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var element = ref.element;
        var index = ref.index;
return [_c('label',{staticClass:"sidebar-item",attrs:{"for":'open-menu-' + index},on:{"click":function($event){_vm.hasChildren(element) ? null : _vm.hideBackdrop()}}},[_c(_vm.hasChildren(element) ? 'UiParagraph': 'UiLink',_vm._b({tag:"component",staticClass:"navbar-link",class:("main-navbar-link-" + (index + 1))},'component',{ target: element.target, label: element.label, tabTarget: element.tabTarget },false),[_vm._v(" "+_vm._s(element.label)+" ")]),(_vm.hasChildren(element))?[_c('label',{staticClass:"sidebar-item__arrow",attrs:{"for":'open-menu-' + index}},[_c('UiIcon',{attrs:{"width":"22","height":"22","name":"SimpleArrowRight"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.menuOpen),expression:"menuOpen"}],attrs:{"type":"checkbox","id":'open-menu-' + index,"name":'open-menu-' + index,"true-value":index},domProps:{"checked":Array.isArray(_vm.menuOpen)?_vm._i(_vm.menuOpen,null)>-1:_vm._q(_vm.menuOpen,index)},on:{"change":function($event){var $$a=_vm.menuOpen,$$el=$event.target,$$c=$$el.checked?(index):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.menuOpen=$$a.concat([$$v]))}else{$$i>-1&&(_vm.menuOpen=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.menuOpen=$$c}}}}),_c('div',{staticClass:"sidebar-item-menu"},[_c('div',{staticClass:"nested-item-info"},[_c('label',{attrs:{"for":'open-menu-' + index}}),_c('span',[_vm._v(" "+_vm._s(element.label)+" ")])]),(_vm.menuOpen === index)?_vm._t("submenu",null,null,{ link: element }):_vm._e()],2)]:_vm._e()],2)]}}],null,true)},'UiNav',{ links: _vm.mainLinks },false)),_c('UiNav',_vm._b({staticClass:"Navbar__secondary-links",scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var element = ref.element;
        var index = ref.index;
return [_c('UiLink',_vm._b({staticClass:"navbar-link",class:("external-navbar-link-" + (index + 1))},'UiLink',{ target: element.target, label: element.label, tabTarget: element.tabTarget },false))]}}])},'UiNav',{ links: _vm.externalLinks },false))]],2),(_vm.isLoggedInUser && _vm.profileHelperMenuOpen)?_c('div',{staticClass:"logout-cta"},[_c('button',{staticClass:"additional-link",on:{"click":_vm.logout}},[_c('span',[_vm._v(_vm._s(_vm.$t('front.shared.navbar.NavbarMobileSimple.logout')))]),_c('UiIcon',{attrs:{"name":"Power"}})],1)]):_vm._e()],2)]),_c('MobileBottomMenu',{attrs:{"elements":_vm.constructBottomNav(),"isWaitingForAuth":_vm.isWaitingForAuth,"accountLinkId":_vm.accountLinkId,"selectedMenuItem":_vm.selectedMenuItem,"isLoggedInUser":!!_vm.isLoggedInUser,"isWishlistServiceAvailable":_vm.isServiceAvailable,"wishlist":_vm.wishlist,"cart":_vm.cart},on:{"onMenuItemSelect":function (value) { return _vm.onMenuItemSelect(value); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }