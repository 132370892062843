import gql from 'graphql-tag'
import { MagentoVersion, QueryRegistry, QueryRegistryFactory } from '../../../contexts'

/**
 * @author Adrian Gajos <adrian.gajos@movecloser.pl>
 */
export const productQueries: QueryRegistryFactory = (config) => {
  const version = config.byKey<MagentoVersion>('magentoVersion', false) ?? MagentoVersion.Default

  const queries: QueryRegistry = {
    productBySku: {
      type: 'query',
      query: gql`
        query GetProductBySKU($sku: String!) {
          products(filter: { sku: { eq: $sku } }) {
            items {
              sku
              media_gallery {
                url
                label
                position
                disabled
              }
            }
          }
        }
      `,
      meta: {}
    }
  }

  if (version === MagentoVersion.Ver24) {
    //
  }

  return queries
}
